const optionsNameMappingForSeparatorFromLeftHalfAndFromRightHalf = {
    trianglesToLeftRight: {
        nameOfPathsLeft: "fromLeftTriangleHalf",
        nameOfPathsRight: "fromRightRising5xSquareHalf",
        maskNameLeft: 'maskP1',
        maskNameRight: 'maskP1V'
    },
    trianglesToLeft066Right033: {
        nameOfPathsLeft: "fromLeftTriangle066",
        nameOfPathsRight: "fromRightRising5xSquare033",
        maskNameLeft: 'maskP2',
        maskNameRight: 'maskP2V'
    },
    holeHalf: {
        nameOfPathsLeft: "fromLeftDecliningHoleHalf",
        nameOfPathsRight: "fromRightRisingHoleHalf",
        maskNameLeft: 'maskP3',
        maskNameRight: 'maskP3V'
    },
    holeL033P066: {
        nameOfPathsLeft: "fromLeftDecliningHole033",
        nameOfPathsRight: "fromRightRisingHole066",
        maskNameLeft: 'maskP4',
        maskNameRight: 'maskP4V'
    },
    triangleDownDeformedHalf: {
        nameOfPathsLeft: "fromLeftTriangleToDownConvexConcaveHalf",
        nameOfPathsRight: "fromRightTriangleToDownConcaveConvexHalf",
        maskNameLeft: 'maskP5',
        maskNameRight: 'maskP6V'
    },
    triangleDownDeformedL066R033: {
        nameOfPathsLeft: "fromLeftTriangleToDownConvexConcave066",
        nameOfPathsRight: "fromRightTriangleToDownConcaveConvex033",
        maskNameLeft: 'maskP8',
        maskNameRight: 'maskP8V'
    },
    triangle3AlternatingDiminishingHalf: {
        nameOfPathsLeft: "fromLeftDeclining3xTriangleHalf",
        nameOfPathsRight: "fromRightRising3xTriangleHalf",
        maskNameLeft: 'mask1',
        maskNameRight: 'mask1V',
    },
    triangle3AlternatingDiminishingHalfL033P066: {
        nameOfPathsLeft: "fromLeftDeclining3xTriangle033",
        nameOfPathsRight: "fromRightRising3xTriangle066",
        maskNameLeft: 'mask2',
        maskNameRight: 'mask2V',
    },
    triangle1DiminishingHalf: {
        nameOfPathsLeft: "fromLeftDeclining1xTriangleHalf",
        nameOfPathsRight: "fromRightRising1xTriangleHalf",
        maskNameLeft: 'mask4',
        maskNameRight: 'mask4V'
    },
    triangle1DiminishingL066R033: {
        nameOfPathsLeft: "fromLeftDeclining1xTriangle066",
        nameOfPathsRight: "fromRightRising1xTriangle033",
        maskNameLeft: 'mask3',
        maskNameRight: 'mask3V',
    },
    triangleUpDownHalf: {
        nameOfPathsLeft: "fromLeftTriangleToUpHalf",
        nameOfPathsRight: "fromRightTriangleToDownHalf",
        maskNameLeft: 'mask4',
        maskNameRight: 'mask4V',
    },
    triangleUpDownL033R066: {
        nameOfPathsLeft: "fromLeftTriangleToUp033",
        nameOfPathsRight: "fromRightTriangleToDown066",
        maskNameLeft: 'mask5',
        maskNameRight: 'mask5V',
    },
    xFallingL066R033: {
        nameOfPathsLeft: "fromLeftXFallingToRight066",
        nameOfPathsRight: "fromRightXFallingToRight033",
        maskNameLeft: 'mask5',
        maskNameRight: 'mask5V',
    },
    hillDiminishingL066R033: {
        nameOfPathsLeft: "fromLeftDecliningHill066",
        nameOfPathsRight: "fromRightRisingHill033",
        maskNameLeft: 'mask6',
        maskNameRight: 'mask6V',
    },
    lSquare10DiminishingRSquare5L033R066: {
        nameOfPathsLeft: "fromLeftDeclining10xSquare033",
        nameOfPathsRight: "fromRightRising05xSquare066",
        maskNameLeft: 'mask8',
        maskNameRight: 'mask8V',
    },
    whaleL066R033: {
        nameOfPathsLeft: "fromLeftWhale066",
        nameOfPathsRight: "fromRightWhale033",
        maskNameLeft: 'mask9',
        maskNameRight: 'mask9V',
    },
    whaleTriangleL033R066: {
        nameOfPathsLeft: "fromLeftWhaleTriangle033",
        nameOfPathsRight: "fromRightWhaleTriangle066",
        maskNameLeft: 'mask10',
        maskNameRight: 'mask10V',
    },
    gooseTriangleL066R033: {
        nameOfPathsLeft: "fromLeftGooseTriangle066",
        nameOfPathsRight: "fromRightGooseTriangle033",
        maskNameLeft: 'mask11',
        maskNameRight: 'mask11V',
    },
    swallowL066R033: {
        nameOfPathsLeft: "fromLeftSwallowToFlyTriangle066",
        nameOfPathsRight: "fromRightSwallowToFlyTriangle033",
        maskNameLeft: 'mask12',
        maskNameRight: 'mask12V',
    },
    wingOfBirdL033R066: {
        nameOfPathsLeft: "fromLeftLeftWingOfBirdTriangle033",
        nameOfPathsRight: "fromRightRightWingOfBirdTriangle066",
        maskNameLeft: 'mask13',
        maskNameRight: 'mask7',
    },


};

export { optionsNameMappingForSeparatorFromLeftHalfAndFromRightHalf }
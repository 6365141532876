import React from 'react';
import useTheme from '@mui/system/useTheme';
import { Box } from '@mui/material';
import DivWithWavedClipPathAnimation from "./DivWithWavedClipPathAnimation"
// import { configWavePathsD, svgPathDSinusWavePathDRelativeToCenter, svgPathsDRelativeToCenterOfCirclesAround, svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround, SvgWithPathsOfOuterCircleAndSmallCirclesAround } from "../../utils/calculateSvgPath"
import { optionsNameMappingForSeparatorFromLeftHalfAndFromRightHalf } from "./separatorFromLeftHalfAndFromRightHalfMappingOptions"


const defaultOptions = {
    nameOfPathsLeft: "fromLeftTriangleHalf",
    nameOfPathsRight: "fromRightRising5xSquareHalf",
    maskNameLeft: 'maskP5',
    maskNameRight: 'maskP5',
    fromGradientColor1: "primary",
    fromGradientColor2: "secondary",
    toGradientColor1: "secondary2",
    toGradientColor2: "warning",
    styleDiv: {
        height: "100px",
        backgroundColor: "green",
        minHeight: "unset",

    },
}


// const baseSine = svgPathDSinusWavePathDRelativeToCenter({ amplitude: 0.5, frequency: 1, phase: 0, length: 1 })
// console.log("baseSine", baseSine)
// const baseCosine = svgPathDSinusWavePathDRelativeToCenter({ amplitude: 0.5, frequency: 1, phase: Math.PI / 2, length: 1 })
// console.log("baseCosine", baseCosine)

const SeparatorFromLeftHalfAndFromRightHalf = ({ optionsName, ...options }) => {
    const optionsFromName = optionsNameMappingForSeparatorFromLeftHalfAndFromRightHalf[ optionsName ] || {};
    const mergedOptions = { ...defaultOptions, ...optionsFromName, ...options }
    const { nameOfPathsLeft, nameOfPathsRight, maskNameLeft, maskNameRight, fromGradientColor1, fromGradientColor2, toGradientColor1, toGradientColor2 } = mergedOptions
    const theme = useTheme();
    const bgImageFromGradientColor1 = theme.palette[ fromGradientColor1 ].main
    const bgImageFromGradientColor2 = theme.palette[ fromGradientColor2 ].main
    const bgImageToGradientColor1 = theme.palette[ toGradientColor1 ].main
    const bgImageToGradientColor2 = theme.palette[ toGradientColor2 ].main

    return (<Box sx={{
        position: "relative",
        height: "50px",
    }}>
        <DivWithWavedClipPathAnimation
            nameOfPaths={nameOfPathsLeft} //"fromLeftTriangleHalf" //"sinusWaveConvexConcaveHoriz" //"sinusWave10xAlternateConvexConcaveHorizFull" //sinusWaveConcaveHoriz
            clipPathId={`SeparatorWaved-${maskNameLeft}-${nameOfPathsLeft}`}
            bgImageFromGradientColor1={bgImageFromGradientColor1}
            bgImageFromGradientColor2={bgImageFromGradientColor2}
            bgImageToGradientColor1={bgImageToGradientColor1}
            bgImageToGradientColor2={bgImageToGradientColor2}
            maskName={maskNameLeft}
            // isMaskPatternHorizontal={true}
            styleDiv={{
                ...mergedOptions.styleDiv,
                height: "inherit",
                position: "absolute",
                top: 0, left: 0, width: "100%",
                // height: "100%" 
            }}
            isFitMaskHeightToImg={false}
        />
        <DivWithWavedClipPathAnimation
            nameOfPaths={nameOfPathsRight} //"sinusWaveConvexConcaveHoriz" //"sinusWave10xAlternateConvexConcaveHorizFull" //sinusWaveConcaveHoriz
            clipPathId={`SeparatorWaved-${maskNameRight}-${nameOfPathsRight}`}
            bgImageFromGradientColor1={bgImageFromGradientColor1}
            bgImageFromGradientColor2={bgImageFromGradientColor2}
            bgImageToGradientColor1={bgImageToGradientColor1}
            bgImageToGradientColor2={bgImageToGradientColor2}
            maskName={maskNameRight}
            // isMaskPatternHorizontal={true}
            styleDiv={{
                ...mergedOptions.styleDiv,
                height: "inherit",
                position: "absolute", top: 0, left: 0,
                width: "100%",
                // height: "100%" 
            }}
            isFitMaskHeightToImg={false}
        />
    </Box>
    )
}

export default SeparatorFromLeftHalfAndFromRightHalf;